<template>
  <div
    ref="ticksouter"
    class="flex flex-col w-full h-8 overflow-hidden border-t border-gray-700 timeline-grid-content-layers timeline-grid"
  >
    <div
      :style="outerStyle"
      class="relative h-8 bg-gray-800 timeline-content-area"
    >
      <div
        ref="tickscontainer"
        class="sticky top-0 z-20 h-full font-mono bg-gray-800 border-gray-700 select-none"
        :style="rowStyle"
      >
        <div
          class="relative w-full h-full cursor-pointer"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"
        >
          <div class="absolute top-0 z-50 w-full h-full opacity-0" />
          <div
            v-if="initd3 && supress === false"
            id="ticksvg"
          />
        </div>

        <div
          v-if="showTick"
          class="absolute bottom-0 z-10 flex items-end h-full scrub-handle"
          :style="tickStyle"
        >
          <fa-icon
            icon="delete-left"
            rotation="270"
            size="lg"
            class="text-gray-300"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import d3Ticks from '@mixins/d3-ticks'
// import * as d3 from 'd3'

export default {

  mixins: [ d3Ticks ],
  props: ['scale', 'scroll', 'overallduration', 'currentProgresspc'],

  data () {
    return {
      supress: false,
      drawnScale: null,

      dragging: false,
      basePc: null,
      outerHeight: 32
    }
  },

  computed: {
    outerStyle () {
      if (this.outerWidth === null) return ''
      return 'width: ' + this.outerWidth + 'px'
    },

    showTick () {
      return (this.currentProgresspc !== false)
    },

    tickStyle () {
      return 'left : ' + this.currentProgresspc + '%'
    },

    rowStyle () {
      if (this.width === null) return ''
      return 'width: ' + this.width + 'px'
    },

    width () {
      let width = this.scale * this.overallduration
      return width.toFixed(0)
    },

    // widthScaled () {
    //   return this.width / this.internalScale
    // },

    skipDraw () {
      return (this.drawnScale === this.width || this.outerWidth === null || this.outerHeight === null)
    }
  },

  // This is a terrible way to do this.
  // It'll work for a visual workaround for now
  // but this needs a revisit
  watch: {
    scale (value) {
      this.initd3 = false
      this.delayedD3Draw()
    },
    scroll (value) {
      this.lockScroll()
    }
    // widthScaled (value) {
    //   let newscale = 100 / value
    //   newscale = newscale.toFixed(2)

    //   this.$emit('update-scale', newscale)
    // },

  },

  mounted () {
    this.lockScroll()
    this.attachScrollListener()
    // this.attachWheelListener()// @todo, get this behaving
  },

  beforeDestroy () {
    this.dettachScrollListener()
    // this.dettachWheelListener()
  },

  methods: {

    lockScroll () {
      const scrollPx = Math.round(this.scrollWidth * this.scroll)

      this.$nextTick(() => {
        if (this.$refs.ticksouter !== undefined) {
          this.$refs.ticksouter.scrollLeft = scrollPx
        }
      })
    },

    // attachWheelListener () {
    //   this.$nextTick(() => { // this next tick shouldn't be needed but it its :(
    //     console.log('Attach wheel')
    //     this.$refs.tickscontainer.addEventListener('wheel', this.onWheel, { passive: true })
    //   })
    // },

    // dettachWheelListener () {
    //   this.$refs.tickscontainer.removeEventListener('wheel', this.onWheel, true)
    // },

    // onWheel (event) {
    //   let d = 0

    //   if(event.deltaY !== 0) {
    //     if(event.deltaY >= 0) d = 1
    //     else d = -1
    //     this.adjustZoomLevel(d)
    //   }
    //   else {
    //     if(event.deltaX !== 0) {
    //       if(event.deltaX >= 0) d = 1
    //       else d = -1
    //       this.adjustArea(d)
    //     }
    //   }
    // },

    // adjustZoomLevel (delta) {
    //   // let normalizedDelta = delta * this.internalScale
    //   // let updatedWidth = this.width - normalizedDelta
    //   // let updatedEndPoint = this.offsetX + updatedWidth
    //   // let updatedOffsetX = this.offsetX

    //   // if(updatedEndPoint >= 100) {
    //   //   updatedOffsetX = 100 - updatedWidth
    //   // }

    //   // if(updatedWidth <= 100 && updatedWidth >= this.minWidth) {
    //   //   this.width = updatedWidth
    //   //   this.offsetX = updatedOffsetX
    //   // }
    // },

    // adjustArea(delta) {
    //   // let updatedOffsetX = this.offsetX + delta
    //   // let updatedWidth = this.width
    //   // let updatedEndPoint = updatedOffsetX + updatedWidth

    //   // if(updatedEndPoint >= 100) {
    //   //   updatedWidth = this.width
    //   //   updatedOffsetX = 100 - this.width
    //   // }

    //   // if(updatedOffsetX <= 0) {
    //   //   updatedOffsetX = 0
    //   //   updatedWidth = this.width
    //   // }

    //   // if (updatedOffsetX >= 0 && updatedWidth <= 100 && updatedWidth >= this.minWidth) {
    //   //   this.width = updatedWidth
    //   //   this.offsetX = updatedOffsetX
    //   // }
    // },

    attachScrollListener () {
      this.$refs.ticksouter.addEventListener('scroll', this.onScroll)
    },

    dettachScrollListener () {
      this.$refs.ticksouter.removeEventListener('resize', this.onScroll)
    },

    onScroll (event) {
      let offset = this.$refs.ticksouter.scrollLeft
      let scrollPc = offset / this.scrollWidth
      this.$emit('update-scroll', scrollPc)
    },

    onMouseDown (event) {
      event.stopPropagation()

      let offset = event.offsetX
      let width = event.target.clientWidth
      let pc = offset / width

      this.$emit('timeline-to-pc', pc)

      let cumuliative = 0
      this.basePc = pc

      this.$emit('mouse-down', (movementX) => {
        let width = event.target.clientWidth
        let scaled = movementX / width
        cumuliative = cumuliative + scaled

        let newpc = this.basePc + cumuliative
        if (newpc < 0) newpc = 0
        if (newpc > 100) newpc = 100

        this.$emit('timeline-to-pc', newpc)
      })
    },

    onMouseUp (event) {
      event.stopPropagation()
      this.$emit('mouse-down', null)
    }
  }
}
</script>
<style>
svg text, svg line, svg path {
  stroke: #a0aec0; /* gray-500 */
}
svg text {
  letter-spacing: 0.1em;
  font-weight: lighter;
}
</style>
