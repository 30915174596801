<template>
  <div class="timeline-grid sticky top-0 z-50 bg-neutral-gray shadow">
    <TimelinePlaybar
      :is-playing="isPlaying"
      :current-timecode="currentTimecode"
      :current-time="currentTime"
      :duration-formatted="durationFormatted"
      :duration="duration"
      :duration-minimum="durationMinimum"
      @toggle-play="$emit('toggle-play', $event)"
    />

    <!-- <TimelineLayerHeader
      v-bind:duration="duration"
      v-bind:scroll="scroll"
      v-bind:scale="scale"
      v-bind:client-scale="clientScale"
      v-bind:current-progresspc="currentProgresspc"
      v-on:timeline-to-pc="$emit('timeline-to-pc', $event)"
      v-on:update-scroll="$emit('update-scroll', $event)"
      v-on:update-scale="$emit('update-scale', $event)"
      v-on:mouse-down="$emit('mouse-down', $event)"
      /> -->
    <div class="bg-gray-500">
      <TimelineWorkAreaBar
        :scale="scale"
        :scroll="scroll"
        @mouse-down="$emit('mouse-down', $event)"
        @update-scale="$emit('update-scale', $event)"
        @update-scroll="$emit('update-scroll', $event)"
      />

      <TimelineLayerTicks
        :overallduration="duration"
        :scroll="scroll"
        :scale="adjustedScale"
        :current-progresspc="currentProgresspc"
        @timeline-to-pc="$emit('timeline-to-pc', $event)"
        @update-scroll="$emit('update-scroll', $event)"
        @update-scale="$emit('update-scale', $event)"
        @mouse-down="$emit('mouse-down', $event)"
      />
    </div>
  </div>
</template>
<script>
import TimelinePlaybar from './header/TimelinePlaybar'
// import TimelineLayerHeader from './header/TimelineLayerHeader'
import TimelineWorkAreaBar from './header/TimelineWorkAreaBar'
import TimelineLayerTicks from './layers/TimelineLayerTicks'

export default {
  components: {
    TimelinePlaybar,
    TimelineWorkAreaBar,
    TimelineLayerTicks
    // TimelineLayerHeader
  },
  props: {
    scale: {
      type: String,
      required: true
    },
    clientScale: {
      type: Number,
      required: true
    },
    scroll: {
      type: Number,
      required: true
    },
    isPlaying: {
      type: Boolean,
      required: true
    },
    currentTimecode: {
      type: String,
      required: true
    },
    currentTime: {
      type: Number,
      required: true
    },
    durationFormatted: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    durationMinimum: {
      type: Number,
      required: true
    },
    currentProgresspc: {
      default: 0
    }
  },

  computed: {
    adjustedScale () {
      return this.scale * this.clientScale
    }
  }
}
</script>
