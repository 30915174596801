<template>
  <div
    ref="zoomcontainer"
    class="timeline-zoom-area h-4 timeline-content-row-md flex items-center bg-gray-800 overflow-x-hidden"
  >
    <div
      class="h-2 relative select-none outline-none z-10"
      :style="style"
      @mousedown="mouseDown"
    >
      <div
        class="timeline-zoom-bar flex justify-between text-sm rounded-full w-full"
        :class="[ moving ? 'bg-gray-200' : 'bg-gray-600']"
      >
        <button
          class="outline-none h-2 w-6"
          :class="[ resizing ? 'bg-gray-200' : 'bg-gray-400']"
          style="cursor: col-resize;"
          @click.prevent
          @mousedown="mouseDownLeft"
        />

        <button
          class="outline-none h-2 w-6"
          :class="[ resizing ? 'bg-gray-200' : 'bg-gray-400']"
          style="cursor: col-resize;"
          @click.prevent
          @mousedown="mouseDownRight"
        />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['scale', 'scroll'],

  data () {
    return {

      offsetX: 0,
      width: 100,
      minWidth: 5,

      internalScale: null,
      clientScale: null,
      resizing: false,
      moving: false,

      moveTimeout: null
    }
  },

  computed: {
    leftPc () {
      return this.offsetX + '%'
    },

    widthPc () {
      return this.widthScaled + '%'
    },

    widthScaled () {
      return this.width / this.internalScale
    },

    style () {
      return 'width: ' + this.widthPc + '; left: ' + this.leftPc + '; cursor: ew-resize;'
    }
  },

  watch: {
    widthScaled (value) {
      let newscale = 100 / value
      newscale = newscale.toFixed(2)

      this.$emit('update-scale', newscale)
    },

    offsetX (value) {
      if (this.resizing || this.moving) {
        value = (value * this.scale) / 100
        value = value.toFixed(2)
        this.$emit('update-scroll', value)
      }
    },

    scroll (value) {
      if (this.moving || this.resizing) return
      value = value * 100
      this.offsetX = value
    }
  },

  mounted () {
    this.attachWheelListener()
    this.internalScale = 1
    this.width = 100 / this.scale
  },

  beforeDestroy () {
    this.dettachWheelListener()
  },

  methods: {

    attachWheelListener () {
      this.$refs.zoomcontainer.addEventListener('wheel', this.onWheel)
    },

    dettachWheelListener () {
      this.$refs.zoomcontainer.removeEventListener('wheel', this.onWheel)
    },

    onWheel (event) {
      event.preventDefault()
      let d = 0

      if (event.deltaY !== 0) {
        if (event.deltaY >= 0) d = 1
        else d = -1
        this.adjustZoomLevel(d)
      }
      if (event.deltaX !== 0) {
        if (event.deltaX >= 0) d = 1
        else d = -1
        this.adjustArea(d)
      }
    },

    adjustZoomLevel (delta) {
      this.setResizing()
      let normalizedDelta = delta * this.internalScale
      let updatedWidth = this.width - normalizedDelta
      let updatedEndPoint = this.offsetX + updatedWidth
      let updatedOffsetX = this.offsetX

      if (updatedEndPoint >= 100) {
        updatedOffsetX = 100 - updatedWidth
      }

      if (updatedWidth <= 100 && updatedWidth >= this.minWidth) {
        this.width = updatedWidth
        this.offsetX = updatedOffsetX
      }
    },

    adjustArea (delta) {
      this.setMoving()
      let updatedOffsetX = this.offsetX + delta
      let updatedWidth = this.width
      let updatedEndPoint = updatedOffsetX + updatedWidth

      if (updatedEndPoint >= 100) {
        updatedWidth = this.width
        updatedOffsetX = 100 - this.width
      }

      if (updatedOffsetX <= 0) {
        updatedOffsetX = 0
        updatedWidth = this.width
      }

      if (updatedOffsetX >= 0 && updatedWidth <= 100 && updatedWidth >= this.minWidth) {
        this.width = updatedWidth
        this.offsetX = updatedOffsetX
      }
    },

    calcClientScale () {
      this.clientScale = 100 / this.$refs.zoomcontainer.clientWidth
    },

    adjustForContainerScale (movementX) {
      if (this.clientScale === null) this.calcClientScale()
      return movementX * this.clientScale
    },

    mouseDownLeft (event) {
      this.setResizing()
      event.stopPropagation()
      this.calcClientScale()

      this.$emit('mouse-down', (movementX) => {
        movementX = this.adjustForContainerScale(movementX)
        let updatedOffsetX = this.offsetX + movementX
        let updatedWidth = this.width - (movementX * this.internalScale)

        if (updatedOffsetX >= 0 && updatedWidth <= 100 && updatedWidth >= this.minWidth) {
          this.setResizing()
          this.width = updatedWidth
          this.offsetX = updatedOffsetX
        }
      })
    },

    mouseDownRight (event) {
      this.setResizing()
      event.stopPropagation()
      this.calcClientScale()

      this.$emit('mouse-down', (movementX) => {
        movementX = this.adjustForContainerScale(movementX)
        movementX = movementX * this.internalScale
        let updatedWidth = this.width + movementX

        if (updatedWidth <= 100 && updatedWidth >= this.minWidth) {
          this.setResizing()
          this.width = updatedWidth
        }
      })
    },

    mouseDown (event) {
      this.setMoving()
      event.stopPropagation()
      this.calcClientScale()

      this.$emit('mouse-down', (movementX) => {
        movementX = this.adjustForContainerScale(movementX)
        let updatedOffsetX = this.offsetX + movementX

        if (updatedOffsetX >= 0 && ((this.width + updatedOffsetX) <= 100) && this.width >= this.minWidth) {
          this.setMoving()
          this.offsetX = updatedOffsetX
        }
      })
    },

    setResizing () {
      this.setupTimeout()
      this.resizing = true
      this.moving = false
    },

    setMoving () {
      this.setupTimeout()
      this.moving = true
      this.resizing = false
    },

    setupTimeout () {
      clearTimeout(this.moveTimeout)
      this.moveTimeout = setTimeout(() => {
        this.endAll()
      }, 500)
    },

    endAll () {
      this.resizing = false
      this.moving = false
    }

  }
}
</script>
