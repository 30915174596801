<template>
  <div class="bg-gray-800 border-b border-gray-500 timeline-playbar">
    <div class="timeline-playbar-controls">
      <div class="flex items-stretch items-center w-full h-full text-sm border-r border-gray-700">
        <button
          class="px-4 py-3 text-gray-300 bg-gray-800 border-r border-gray-700 play-button hover:text-white focus:outline-none focus:text-gray-800 hover:bg-gray-600 focus:bg-gray-400"
          @click.prevent="togglePlayState"
        >
          <fa-icon
            v-if="!isPlaying"
            icon="play"
          />
          <fa-icon
            v-else
            icon="pause"
          />
        </button>

        <div class="flex items-center justify-center w-full">
        
            <div
              class="p-2 font-mono text-xs text-gray-300 time-indicator "
            >
              {{ currentTimecode }} / {{ durationFormatted }}
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TimleinePlaybar',

  props: [
    'isPlaying', 'currentTimecode', 'durationFormatted', 'duration', 'durationMinimum'
  ],

  methods: {

    togglePlayState () {
      this.$emit('toggle-play')
    }
  }
}

</script>
